import React from "react"
import { Flex } from "@chakra-ui/react"
import NavHeader from "./NavHeader"
import Footer from "./Footer"
import GdprConsent from "./GdprConsent";


export default function LandingLayout(props) {
  if (typeof window !== "undefined") {
    require("smooth-scroll")('a[href*="#"]')
  }

  return (
    <Flex
      direction="column"
      align="center"
      minH="100vh"
      m="0 auto"
      {...props}
    >
      <NavHeader />
      {props.children}
      <Footer />
    </Flex>
  )
}
