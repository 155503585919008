import React from "react";
import { Box, Button, Flex, Icon, Link, Text, useDisclosure } from "@chakra-ui/react";
import { GiHamburgerMenu, AiOutlineClose } from "react-icons/all";
import Logo from "./Logo";
import SignUpModal from "./SignUpModal";
import * as Constants from "../constants";


const MenuItem = ({ children, isLast, to = "/", onOpen, ...rest }) => {
  return (
    <Text
      display="block"
      mr={{ base: 0, md: 5 }}
      zIndex={10}
      {...rest}
    >
      <Link
        href={to}
        fontWeight="600"
        onClick={() => {
          window.analytics.track("Header Item Clicked", {link: to});
          if (to === Constants.AppUrl) {
            onOpen();
          }
        }}
      >
        {children}
      </Link>
    </Text>
  )
}


export default function NavHeader(props) {
  const [show, setShow] = React.useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleToggle = () => {
    setShow(!show);
    console.log(show);
  }

  return (
    <Flex
      id="top"
      h="8vh"
      as="nav"
      align="center"
      justify="space-between"
      w="100%"
      wrap="wrap"
      color="white"
      // position="fixed"
      // zIndex={1}
      // sx={{ backdropFilter: "blur(2px)" }}
      // bg="brand.700"
      {...props}
    >
      <Flex alignItems="center" justify="center" px={6}>
        <Link href="/">
          <Logo style={{ maxWidth: "50px", maxHeight: "50px", marginRight: 15}} />
        </Link>
        <Text fontWeight="bold" fontSize="3xl" align="center" pb={1.5}>Studio</Text>
        <Text fontSize="3xl" pb={1.5}>Trackr</Text>
      </Flex>
      <Box display={{ base: "block", md: "none" }} onClick={handleToggle} p={6}>
        {show ?
          <Icon as={AiOutlineClose} color="white" boxSize="20px" />
          :
          <Icon as={GiHamburgerMenu} color="white" boxSize="20px" />
        }
      </Box>
      <Box
        display={{ base: show ? "block" : "none", md: "block" }}
        flexBasis={{ base: "100%", md: "auto" }}
      >
        <Flex
          align="center"
          justify={["space-between", "space-between", "flex-end", "flex-end"]}
          px={{ base: show ? 4 : 6 }}
          w={{ base: show ? "100%" : "auto", md: "auto" }}
          position={{ base: show ? "absolute" : "relative" }}
        >
          <MenuItem fontWeight={ show ? "bold" : "normal" } to="/#overview">Overview</MenuItem>
          <MenuItem fontWeight={ show ? "bold" : "normal" } to="/#features">Features</MenuItem>
          <MenuItem fontWeight={ show ? "bold" : "normal" } to="/#pricing">Pricing</MenuItem>
          <MenuItem fontWeight={ show ? "bold" : "normal" } isLast={show} to={Constants.AppUrl} onOpen={onOpen}>Sign In</MenuItem>
          <Button
            as="a"
            href={Constants.AppUrl}
            colorScheme="green"
            boxShadow="md"
            display={ show ? "none" : "flex" }
            onClick={() => {
              window.analytics.track("Header Try Now Clicked", {});
              onOpen();
            }}
          >
            Try Now
          </Button>
        </Flex>
      </Box>
      <SignUpModal isOpen={isOpen} onClose={onClose} />
    </Flex>
  )
}
