import React from "react";
import { Box, Flex, Text, VStack, Link, HStack } from "@chakra-ui/react";
import Logo from "./Logo";
import SubscriptionInput from "./SubscriptionInput";


export default function Footer() {
  return (
    <Flex
      align="center"
      justify="center"
      direction={{ base: "column-reverse", md: "row" }}
      wrap="no-wrap"
      minH={{ base: "20vh", md: "30vh" }}
      w="100%"
      px={{ base: 0, md: "8vw" }}
      py={{ base: 10, md: 5 }}
      color="white"
      bg="brand.500"
    >
      <VStack spacing="10" px={{ base: 0, md: "10vw" }} w="100%">
        <Flex
          direction={{ base: "column", md: "row" }}
          align="center"
          data-sal="slide-up"
          data-sal-delay="200"
          data-sal-duration="1000"
        >
          <Box w={{ base: "100%", md: "60%"}}>
            <Text fontSize="3xl" color="white">Get updates from</Text>
          </Box>
          <Flex w={{ base: "100%", md: "40%"}} justify="center">
            <Text fontSize="3xl" fontWeight="bold" color="white">Studio</Text>
            <Text fontSize="3xl" color="white">Trackr</Text>
          </Flex>
        </Flex>
        <Flex
          align="center"
          w={{ base: "90%", xl: "50%" }}
          data-sal="slide-up"
          data-sal-delay="200"
          data-sal-duration="1000"
        >
          <SubscriptionInput />
        </Flex>
        <VStack spacing="5" w={{ base: "90%", lg: "70%" }}>
          <Flex align="center">
            <Logo blue={true} style={{maxWidth: "50px", maxHeight: "50px", marginRight: "15px"}} />
            <Text color="white" fontWeight="bold" fontSize="3xl">Studio</Text>
            <Text color="white" fontSize="3xl">Trackr</Text>
          </Flex>
          <Flex mt="2vh" color="brand.700" fontSize="sm" w="90%" alignItems="center" justify="space-between" direction={{ base: "column", xl: "row" }}>
            <Text textAlign="center" mb={{ base: 4, xl: 0 }}>Copyright {new Date().getFullYear()} by Monument Apps, LLC.</Text>
            <HStack spacing="5" mb={{ base: 4, xl: 0 }}>
              <Link textAlign="center" fontWeight="bold" href="/privacy-policy">Privacy Policy</Link>
              <Link textAlign="center" fontWeight="bold" href="/cookie-policy">Cookie Policy</Link>
              <Link textAlign="center" fontWeight="bold" href="/terms-of-service">Terms of Service</Link>
            </HStack>
          </Flex>
        </VStack>
      </VStack>
    </Flex>
  )
}
