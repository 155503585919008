import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
} from "@chakra-ui/react";
import React from "react";
import SubscriptionInput from "./SubscriptionInput";


export default function SignUpModal({isOpen, onClose}) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl">
      <ModalOverlay />
      <ModalContent bg="brand.500">
        <ModalHeader color="white">Coming Soon!</ModalHeader>
        <ModalCloseButton color="white" />
        <ModalBody>
          <Text color="white">
            Thanks for your interest in StudioTrackr! We are still in development, but enter your email
            below to get launch updates.
          </Text>
        </ModalBody>
        <ModalFooter mb="3vh">
          <SubscriptionInput onSubmit={onClose} />
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
