import { useColorMode } from "@chakra-ui/react";
import React from "react";
import LogoBlack from "../assets/logo_black.svg";
import LogoWhite from "../assets/logo_white.svg";
import LogoBlue from "../assets/logo_blue.svg";


export default function Logo({blue, ...rest}) {
  const { colorMode } = useColorMode();

  if (blue) {
    return <img src={LogoBlue} {...rest}/>
  } else {
    return colorMode === "light" ? <img src={LogoBlack} {...rest} /> : <img src={LogoWhite} {...rest}/>;
  }
}
