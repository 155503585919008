import { Button, Flex, Input, Text } from "@chakra-ui/react";
import React from "react";
import * as yup from "yup";


export default function SubscriptionInput({onSubmit}) {
  const [subscribed, setSubscribed] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [error, setError] = React.useState();
  let schema = yup.object().shape({
    email: yup.string().required().email(),
  })


  const subscribe = () => {
    schema.isValid({email: email})
      .then((valid) => {
        if (valid) {
          setSubscribed(true);
          window.analytics.track("Email Subscribed", {
            email: email,
          });
          window.analytics.identify(window.analytics.user().anonymousId(), {
            email: email
          });
          setEmail("");
          setError();
          onSubmit();
        } else {
          setError("Please enter a valid email address.")
        }
      })
  }

  function handleChange(event) {
    setEmail(event.target.value)
  }

  return (
    <Flex w="100%" flexDir="column">
      <Flex w="100%">
        <Input
          bg="white"
          minW={{ sm: "90%", md: "50%" }}
          placeholder="Email Address"
          value={email}
          onChange={handleChange}
          color="black"
          isInvalid={!!error}
        />
        <Button
          size="md"
          px="8"
          colorScheme="green"
          color="white"
          boxShadow="md"
          ml="5"
          onClick={subscribe}
        >
        Subscribe
        </Button>
      </Flex>
      {subscribed &&
        <Flex align="center" w="100%" justify="center" mt="1vh">
          <Text color="white">Successfully subscribed to updates</Text>
        </Flex>
      }
      {error &&
        <Flex align="center" w="100%" justify="center" mt="1vh">
          <Text color="white">{error}</Text>
        </Flex>
      }
    </Flex>
  )
}
